import React, { Component } from 'react'
import { Marker } from 'react-leaflet'
import IProfile from '../../../model/IProfile'
import PositionService from '../../../services/PositionUtils'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import L from 'leaflet'

interface StateType {
  isProfileModalOpen: boolean
  profileImg: string | undefined
  profileTypes: string[]
  typeSelected: string
  profileTimestamp: number | undefined
}

interface PropsType {
  data: IProfile
}

export default class VerticalProfile extends Component<PropsType, StateType> {
  private positionService: PositionService = new PositionService()

  constructor(props: any) {
    super(props)
    this.state = {
      isProfileModalOpen: false,
      profileImg: '',
      profileTypes: [],
      typeSelected: '',
      profileTimestamp: undefined,
    }

    this.toggleProfileModal = this.toggleProfileModal.bind(this)
    this.fetchProfileData = this.fetchProfileData.bind(this)
    this.updateProfileData = this.updateProfileData.bind(this)
    this.createCustomIcon = this.createCustomIcon.bind(this)
  }

  private fetchProfileData() {
    const date = new Date(this.props.data.timestamp)
    const millis = date.getTime()

    // fetch chart
    const apiChartURL =
      process.env.REACT_APP_API_BASE_URL +
      '/plot/profile/' +
      this.props.data.system +
      '/' +
      this.props.data.type +
      '.png?timestamp=' +
      millis
    fetch(apiChartURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        this.setState({
          profileImg: url,
          profileTimestamp: millis,
          typeSelected: this.props.data.type,
          isProfileModalOpen: !this.state.isProfileModalOpen,
        })
      })
      .catch((error) => {
        console.error('Error fetching the image:', error)
      })

    // fetch profile types
    const apiProfileTypesAvailableURL =
      process.env.REACT_APP_API_BASE_URL + '/plot/profileTypes/' + this.props.data.system + '?timestamp=' + millis
    fetch(apiProfileTypesAvailableURL)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ profileTypes: data })
      })
      .catch((error) => {
        console.error('Error fetching the image:', error)
      })
  }

  private updateProfileData(profileType: string) {
    // fetch chart
    const apiChartURL =
      process.env.REACT_APP_API_BASE_URL +
      '/plot/profile/' +
      this.props.data.system +
      '/' +
      profileType +
      '.png?timestamp=' +
      this.state.profileTimestamp
    fetch(apiChartURL)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob)
        this.setState({ profileImg: url, typeSelected: profileType })
      })
      .catch((error) => {
        console.error('Error fetching the image:', error)
      })
  }

  private toggleProfileModal() {
    this.setState({ isProfileModalOpen: !this.state.isProfileModalOpen })
  }

  // Utility function to create a custom icon
  private createCustomIcon() {
    let colorRGB = 'black'
    if (this.props.data.type === 'Temperature') {
      const surfaceTemperature = getValueAtDepthZero(this.props.data.samples)
      if (surfaceTemperature) colorRGB = getColorByTemperature(surfaceTemperature)
    }

    return L.divIcon({
      className: 'custom-temperature-icon',
      html: `<div style="color:${colorRGB}" class="fa fa-times-circle"></div>`,
      iconSize: [5, 5],
      iconAnchor: [5, 5],
    })
  }

  public render() {
    const systemPosition = this.positionService.getLatLng(this.props.data)

    // img url
    const imgUrl =
      process.env.REACT_APP_API_BASE_URL +
      '/plot/profile/' +
      this.props.data.system +
      '/' +
      this.state.typeSelected +
      '.png?timestamp=' +
      this.state.profileTimestamp

    return (
      <Marker position={systemPosition} icon={this.createCustomIcon()} onClick={this.fetchProfileData}>
        <Modal isOpen={this.state.isProfileModalOpen} className="sixty-percent-modal" toggle={this.toggleProfileModal}>
          <ModalHeader toggle={this.toggleProfileModal}>
            {' '}
            {this.props.data.system + ' | ' + this.props.data.timestamp + ' | ' + this.state.typeSelected}{' '}
          </ModalHeader>
          <ModalBody>
            {this.state.profileImg ? (
              <a href={imgUrl} target="_blank" rel="noopener noreferrer">
                {' '}
                <img src={this.state.profileImg} alt="Fetched from URL" className="img-fluid" />{' '}
              </a>
            ) : (
              'Loading...'
            )}
          </ModalBody>

          <ModalFooter>
            {this.state.profileTypes.length <= 5 ? (
              this.state.profileTypes.map((typeName) => {
                return (
                  <Button
                    key={'profileType-' + typeName}
                    color={this.state.typeSelected === typeName ? 'primary' : 'secondary'}
                    onClick={() => this.updateProfileData(typeName)}
                  >
                    {typeName}
                  </Button>
                )
              })
            ) : (
              <select value={this.state.typeSelected} onChange={(e) => this.updateProfileData(e.target.value)}>
                {this.state.profileTypes.map((typeName) => {
                  return (
                    <option key={'profileType-' + typeName} value={typeName}>
                      {typeName}
                    </option>
                  )
                })}
              </select>
            )}
          </ModalFooter>
        </Modal>
      </Marker>
    )
  }
}

// Convert temperature to RGB color
function getColorByTemperature(temp: number) {
  const clampedTemp = Math.max(12, Math.min(22, temp))
  const hue = ((22 - clampedTemp) / (22 - 12)) * 240
  const rgb = hsvToRgb(hue, 1, 1)
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`
}

// Convert HSV to RGB
function hsvToRgb(h: number, s: number, v: number) {
  const f = (n: number, k = (n + h / 60) % 6) => v - v * s * Math.max(Math.min(k, 4 - k, 1), 0)
  return [f(5), f(3), f(1)].map((x) => Math.round(x * 255))
}

function getValueAtDepthZero(samples: number[][]) {
  if (!samples || samples.length === 0) {
    return null // Return null if no samples are available
  }

  let closestSample = samples[0]
  let minDepthDiff = Math.abs(closestSample[0])

  for (let i = 1; i < samples.length; i++) {
    const [depth] = samples[i]
    const depthDiff = Math.abs(depth)

    // Check if this depth is closer to 0 than the current closest
    if (depthDiff < minDepthDiff) {
      minDepthDiff = depthDiff
      closestSample = samples[i]
    }
  }

  return closestSample[1]
}
