import UserState, { isL0, isL3, isL4 } from '../../../model/IAuthState'

const { NotificationManager } = require('react-notifications')

interface LayerInfo {
  name: string
  description: string
  units: string
  lowerValue: number
  higherValue: number
  isLog: boolean
  url?: string
  style?: string
}

interface LayerSettings {
  name: string
  group: string
  // If the layer has a legendStyle, then a scale will be drawn beneath the layer's selection box
  legendStyle?: string
  opacity?: number
  // Units to display on the layer's scale
  units?: string
  lowerValue?: number
  higherValue?: number
  isLog?: boolean
  // For layers with multiple sublayers indicate whether you want to show the sublayer's name or the orignal layer's name
  changeLayerName?: boolean
  // For layers with multiple sublayers indicate what layer you want active as default
  activeLayer?: string
  // Indicate what sublayers you want to display (dropdown selector)
  subLayers?: Map<string, LayerInfo>
  // If the layers has a fileName, then a upload prompt will be drawn beneath the layer's selection box
  fileName?: string
  layerName?: string
  times?: string[]
  time?: string
}

class Sidebar {
  public ripplesLayers = new Map<string, LayerSettings>([
    ['OpenStreetMap', { name: 'OpenStreetMap', group: 'Maps' }],
    ['ArcGIS NatGeo', { name: 'ArcGIS NatGeo', group: 'Maps' }],
    ['ArcGIS Ocean', { name: 'ArcGIS Ocean', group: 'Maps' }],
    ['ArcGis World Imagery', { name: 'ArcGis World Imagery', group: 'Maps' }],
    ['Thunder Forest', { name: 'Thunder Forest', group: 'Maps' }],
    ['GMRT', { name: 'GMRT', group: 'Maps' }],
    ['ENC', { name: 'ENC', group: 'Maps' }],
    ['Dark mode', { name: 'Dark mode', group: 'Maps' }],
    ['Vehicles', { name: 'Vehicles', group: 'Assets' }],
    ['Spots', { name: 'Spots', group: 'Assets' }],
    ['CCUS', { name: 'CCUS', group: 'Assets' }],
    ['Plans', { name: 'Plans', group: 'Operator Data' }],
    ['AIS Data', { name: 'AIS Data', group: 'Operator Data' }],
    ['AIS Density', { name: 'AIS Density', group: 'Operator Data' }],
    ['Aircraft Data', { name: 'Aircraft Data', group: 'Operator Data' }],
    ['Bathymetry', { name: 'Bathymetry', group: 'Operator Data' }],
    ['Map Elements', { name: 'Map Elements', group: 'Operator Data' }],
    ['Follow Asset', { name: 'Follow Asset', group: 'Operator Data' }],
    [
      'Chl Concentration',
      {
        name: 'Chl Concentration',
        group: 'Scientist Data',
        legendStyle: 'ferret',
        opacity: 0.4,
        activeLayer: 'chl',
        subLayers: new Map<string, LayerInfo>([
          [
            'chl',
            {
              name: 'chl',
              description: 'Chl Concentration',
              units: 'mg/m³',
              lowerValue: 0.01,
              higherValue: 10,
              isLog: true,
            },
          ],
          [
            'fe',
            {
              name: 'fe',
              description: 'Iron Concentration',
              units: 'mmol/m³',
              lowerValue: 0.01,
              higherValue: 1,
              isLog: true,
            },
          ],
          [
            'nh4',
            {
              name: 'nh4',
              description: 'Ammonium Concentration',
              units: 'mmol/m³',
              lowerValue: 0.01,
              higherValue: 10,
              isLog: true,
            },
          ],
          [
            'no3',
            {
              name: 'no3',
              description: 'Nitrate Concentration',
              units: 'mmol/m³',
              lowerValue: 0.01,
              higherValue: 100,
              isLog: true,
            },
          ],
          [
            'o2',
            {
              name: 'o2',
              description: 'Oxygen Concentration',
              units: 'mmol/m³',
              lowerValue: 200,
              higherValue: 300,
              isLog: false,
            },
          ],
          [
            'phyc',
            {
              name: 'phyc',
              description: 'Phytoplankton Expressed as Carbon Concentration',
              units: 'mmol/m³',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
            },
          ],
          [
            'po4',
            {
              name: 'po4',
              description: 'Phosphate Concentration',
              units: 'mmol/m³',
              lowerValue: 0.01,
              higherValue: 2,
              isLog: true,
            },
          ],
          [
            'nppv',
            {
              name: 'nppv',
              description: 'Net Primary Productivity of Carbon',
              units: 'mmol/m³/day',
              lowerValue: 0.01,
              higherValue: 50,
              isLog: false,
            },
          ],
          [
            'si',
            {
              name: 'si',
              description: 'Silicate Concentration',
              units: 'mmol/m³',
              lowerValue: 0.75,
              higherValue: 10,
              isLog: true,
            },
          ],
          [
            'dissic',
            {
              name: 'dissic',
              description: 'Dissolved Inorganic Carbon',
              units: 'mmol/m³',
              lowerValue: 2,
              higherValue: 2.5,
              isLog: false,
            },
          ],
          [
            'ph',
            { name: 'ph', description: 'Sea Water ph', units: 'n/a', lowerValue: 0.01, higherValue: 10, isLog: false },
          ],
          [
            'zeu',
            {
              name: 'zeu',
              description: 'Euphotic Zone Depth',
              units: 'm',
              lowerValue: 0.01,
              higherValue: 500,
              isLog: false,
            },
          ],
          [
            'spco2',
            {
              name: 'spco2',
              description: 'Surface Partial Pressure of Carbon Dioxide',
              units: 'Pa',
              lowerValue: 30,
              higherValue: 60,
              isLog: false,
            },
          ],
        ]),
        lowerValue: 0.01,
        higherValue: 10,
      },
    ],

    [
      'Sea Level Anomaly',
      {
        name: 'Sea Level Anomaly',
        group: 'Scientist Data',
        legendStyle: 'redblue',
        opacity: 0.55,
        units: 'm',
        lowerValue: -0.8,
        higherValue: 0.8,
      },
    ],
    [
      'Sea Surface Salinity',
      {
        name: 'Sea Surface Salinity',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.3,
        units: '10⁻³',
        lowerValue: 33.0,
        higherValue: 36.0,
      },
    ],
    [
      'Sea Surface Temperature',
      {
        name: 'Sea Surface Temperature',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.4,
        units: '°C',
        lowerValue: 12,
        higherValue: 28,
        activeLayer: 'thetao',
        changeLayerName: false,
        subLayers: new Map<string, LayerInfo>([
          [
            'thetao',
            {
              name: 'thetao',
              description: 'Sea Surface Temperature - CMEMS',
              units: '°C',
              lowerValue: 12,
              higherValue: 28,
              isLog: false,
              url: 'https://nrt.cmems-du.eu/thredds/wms/cmems_mod_ibi_phy_anfc_0.027deg-3D_P1D-m',
              style: 'boxfill/rainbow',
            },
          ],
          [
            'sst',
            {
              name: 'sst',
              description: 'Sea Surface Temperature - MUR',
              units: '°C',
              lowerValue: 12,
              higherValue: 28,
              isLog: false,
              url: 'https://rampsrv.lsts.pt/thredds/wms/MUR/latest.nc',
              style: 'raster/default',
            },
          ],
        ]),
      },
    ],
    [
      'Sea Surface Velocity',
      {
        name: 'Sea Surface Velocity',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.35,
        units: 'm/s',
        lowerValue: 0.01,
        higherValue: 2,
      },
    ],
    [
      'Waves',
      {
        name: 'Waves',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.4,
        units: 'm',
        lowerValue: 0.01,
        higherValue: 10,
      },
    ],

    [
      'Wind',
      {
        name: 'Wind',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.4,
        units: 'm/s',
        lowerValue: 0.01,
        higherValue: 23,
      },
    ],
    [
      'Ocean Front Identification',
      {
        name: 'Ocean Front Identification',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.5,
        units: 'n/a',
        activeLayer: 'CCA',
        changeLayerName: false,
        subLayers: new Map<string, LayerInfo>([
          [
            'CCA',
            {
              name: 'CCA',
              description: 'Cayula Cornillon Algorithm',
              units: 'n/a',
              lowerValue: 0,
              higherValue: 20,
              isLog: false,
            },
          ],
          [
            'Canny',
            {
              name: 'Canny',
              description: 'Canny',
              units: 'n/a',
              lowerValue: 0,
              higherValue: 20,
              isLog: false,
            },
          ],
          [
            'BOA',
            {
              name: 'BOA',
              description: 'Belkin O Reilly Algorithm',
              units: 'n/a',
              lowerValue: 0,
              higherValue: 20,
              isLog: false,
            },
          ],
        ]),
        lowerValue: 0.01,
        higherValue: 20,
      },
    ],
    [
      'Ocean Eddy Identification',
      {
        name: 'Ocean Eddy Identification',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.5,
        units: 'n/a',
        lowerValue: 0.01,
        higherValue: 10,
      },
    ],
    [
      'HOPS - Temperature',
      {
        name: 'HOPS - Temperature',
        group: 'Scientist Data',
        legendStyle: 'x-Rainbow',
        opacity: 0.5,
        units: '°C',
        lowerValue: 12,
        higherValue: 20,
      },
    ],
    [
      'HOPS - Temperature error',
      {
        name: 'HOPS - Temperature error',
        group: 'Scientist Data',
        legendStyle: 'x-Rainbow',
        opacity: 0.5,
        units: 'n/a',
        lowerValue: 0,
        higherValue: 1,
      },
    ],
    [
      'HOPS - Salinity',
      {
        name: 'HOPS - Salinity',
        group: 'Scientist Data',
        legendStyle: 'x-Rainbow',
        opacity: 0.5,
        units: '10⁻³',
        lowerValue: 33,
        higherValue: 36,
      },
    ],
    [
      'HOPS - Salinity error',
      {
        name: 'HOPS - Salinity error',
        group: 'Scientist Data',
        legendStyle: 'x-Rainbow',
        opacity: 0.5,
        units: 'n/a',
        lowerValue: 0,
        higherValue: 1,
      },
    ],
    [
      'NetCDF File',
      {
        name: 'NetCDF File',
        group: 'Scientist Data',
        legendStyle: 'rainbow',
        opacity: 0.5,
        units: 'n/a',
        lowerValue: 0.01,
        higherValue: 10,
        fileName: '',
        layerName: '',
      },
    ],
    [
      'Sea Surface Salinity - MOHID',
      {
        name: 'Sea Surface Salinity - MOHID',
        group: 'Hi-Res Model REP',
        units: '1e-3',
        legendStyle: 'rainbow',
        opacity: 0.4,
        lowerValue: 0.01,
        higherValue: 35,
        times: ['00:00:00Z', '03:00:00Z', '06:00:00Z', '09:00:00Z', '12:00:00Z', '15:00:00Z', '18:00:00Z', '21:00:00Z'],
        time: '00:00:00Z',
      },
    ],
    [
      'Sea Surface Current - MOHID',
      {
        name: 'Sea Surface Current - MOHID',
        group: 'Hi-Res Model REP',
        units: 'm/s',
        legendStyle: 'rainbow',
        opacity: 0.4,
        lowerValue: 0.01,
        higherValue: 2,
        times: ['00:00:00Z', '03:00:00Z', '06:00:00Z', '09:00:00Z', '12:00:00Z', '15:00:00Z', '18:00:00Z', '21:00:00Z'],
        time: '00:00:00Z',
      },
    ],
    [
      'Sea Surface Temperature - MOHID',
      {
        name: 'Sea Surface Temperature - MOHID',
        group: 'Hi-Res Model REP',
        units: '°C',
        legendStyle: 'rainbow',
        opacity: 0.4,
        lowerValue: 10,
        higherValue: 25,
        times: ['00:00:00Z', '03:00:00Z', '06:00:00Z', '09:00:00Z', '12:00:00Z', '15:00:00Z', '18:00:00Z', '21:00:00Z'],
        time: '00:00:00Z',
      },
    ],
    [
      'Bathymetry - MOHID',
      {
        name: 'Bathymetry - MOHID',
        group: 'Hi-Res Model REP',
        units: 'm',
        legendStyle: 'ferret',
        opacity: 0.4,
        lowerValue: -2,
        higherValue: 250,
      },
    ],
    [
      'Risk Management',
      {
        name: 'Risk Management',
        group: 'CUXV',
        units: 'n/a',
        legendStyle: 'x-Rainbow',
        opacity: 0.4,
        activeLayer: 'RAC_currents',
        lowerValue: 0.01,
        higherValue: 5,
        changeLayerName: false,
        subLayers: new Map<string, LayerInfo>([
          [
            'RAC_currents',
            {
              name: 'RAC_currents',
              description: 'Risk for currents',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
              url: '/wms/aos/RAC_glider_latest.nc',
            },
          ],
          [
            'RAC_waves',
            {
              name: 'RAC_waves',
              description: 'Risk for waves',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
              url: '/wms/aos/RAC_glider_latest.nc',
            },
          ],
          [
            'risk_code_glider',
            {
              name: 'risk_code_glider',
              description: 'Integrated risk wind-waves-currents',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 6.5,
              isLog: false,
              url: '/wms/aos/RAC_glider_latest.nc',
            },
          ],
          [
            'risk_code_sar',
            {
              name: 'risk_code_sar',
              description: 'Risk for Buoys Dispersion',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
              url: '/wms/aos/RAC_buoys_latest.nc',
            },
          ],
        ]),
      },
    ],
    [
      'Acoustic Performance',
      {
        name: 'Acoustic Performance',
        group: 'CUXV',
        units: 'n/a',
        legendStyle: 'x-Rainbow',
        opacity: 0.4,
        activeLayer: 'acRanges_hfreq',
        changeLayerName: false,
        lowerValue: 0.01,
        higherValue: 10,
        subLayers: new Map<string, LayerInfo>([
          [
            'acRanges_hfreq',
            {
              name: 'acRanges_hfreq',
              description: 'Acoustic Range proxy',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 10,
              isLog: false,
              url: '/wms/aos/repmus_effRanges_mohidSM_latest.nc',
            },
          ],
          [
            'RAC_blg',
            {
              name: 'RAC_blg',
              description: 'Risk for Sound Speed Gradients',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
              url: '/wms/aos/RAC_genP_latest.nc',
            },
          ],
          [
            'risk_code_SFCgenP',
            {
              name: 'risk_code_SFCgenP',
              description: 'Risk for acoustic passive detection',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
              url: '/wms/aos/RAC_genP_latest.nc',
            },
          ],
        ]),
      },
    ],
    [
      'Mission Planning',
      {
        name: 'Mission Planning',
        group: 'CUXV',
        units: 'n/a',
        legendStyle: 'x-Rainbow',
        opacity: 0.4,
        activeLayer: 'risk_code_qroute',
        lowerValue: 0.01,
        higherValue: 7,
        changeLayerName: false,
        subLayers: new Map<string, LayerInfo>([
          [
            'risk_code_qroute',
            {
              name: 'risk_code_qroute',
              description: 'Drift from area C2.3',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
              url: '/wms/aos/RAC_Qroute_latest.nc',
            },
          ],
          [
            'risk_code_qroute_2',
            {
              name: 'risk_code_qroute',
              description: 'Drift from the middle of area C2.3 after 12 hours',
              units: 'n/a',
              lowerValue: 0.01,
              higherValue: 5,
              isLog: false,
              url: '/wms/aos/RAC_BDRIFT_latest.nc',
            },
          ],
        ]),
      },
    ],
    ['Annotations', { name: 'Annotations', group: 'Others' }],
    ['Current Location', { name: 'Current Location', group: 'Others' }],
    ['Measure Track', { name: 'Measure Track', group: 'Others' }],
    ['Pollution Data', { name: 'Pollution Data', group: 'Projects' }],
    ['Contacts', { name: 'Contacts', group: 'Projects' }],
  ])

  public scientistLayers = [
    'Chl Concentration',
    'Sea Level Anomaly',
    'Sea Surface Salinity',
    'Sea Surface Temperature',
    'Sea Surface Velocity',
    'Waves',
    'Wind',
    'Ocean Front Identification',
    'Ocean Eddy Identification',
    'HOPS - Temperature',
    'HOPS - Temperature error',
    'HOPS - Salinity',
    'HOPS - Salinity error',
    'NetCDF File',
    'Sea Surface Salinity - MOHID',
    'Sea Surface Current - MOHID',
    'Sea Surface Temperature - MOHID',
    'Bathymetry - MOHID',
    'Risk Management',
    'Acoustic Performance',
    'Mission Planning',
  ]

  public loading: boolean
  public isDarkMode: boolean

  constructor() {
    this.loading = true
    this.isDarkMode = false
  }

  public buildSidebar(auth: UserState) {
    this.injectLayerControlGroups()
    this.injectLayerScale()
    this.roleSideBarView(auth)
  }

  public toggleDarkMode(darkMode: boolean) {
    this.isDarkMode = darkMode
  }

  // Toggle sidebar visibility (show or hide)
  public toggleSidebar(auth: UserState) {
    const sideBar = Array.from(
      document.getElementsByClassName('leaflet-control-layers') as HTMLCollectionOf<HTMLElement>
    )

    if (sideBar[0] !== undefined) {
      sideBar[0].style.display === 'none' ? (sideBar[0].style.display = 'flex') : (sideBar[0].style.display = 'none')
    }

    if (this.loading && sideBar[0] !== undefined && sideBar[0].style.display === 'flex') {
      this.buildSidebar(auth)
      this.loading = false
    }
  }

  // Close a layer group div
  public closeGroup(groupName: string) {
    const Group = Array.from(document.getElementsByClassName(groupName) as HTMLCollectionOf<HTMLElement>)
    Group.forEach((element) => {
      element.style.display = 'none'
    })
    const GroupHeader = Array.from(
      document.getElementsByClassName(groupName + '-i') as HTMLCollectionOf<HTMLElement>
    )[0] as HTMLElement
    if (GroupHeader !== undefined) GroupHeader.className = 'fas fa-angle-double-down fa-lg ' + groupName + '-i'
  }

  // Close layer groups according to each user role
  public roleSideBarView(auth: UserState) {
    if (!isL0(auth) || isL3(auth)) {
      this.closeGroup('maps')
      this.closeGroup('operator-data')
      this.closeGroup('others')
    } else if (!isL0(auth) || isL3(auth)) {
      this.closeGroup('maps')
      this.closeGroup('scientist-data')
      this.closeGroup('others')
    } else if (isL0(auth)) {
      this.closeGroup('maps')
      this.closeGroup('others')
      this.closeGroup('mymaps')
      this.closeGroup('projects')
    } else if (isL4(auth) || !auth.authenticated) {
      this.closeGroup('operator-data')
      this.closeGroup('scientist-data')
    }
  }

  // Toggle layer group visibility (show or hide)
  public toggleGroupVisibility(className: string) {
    const children = Array.from(document.getElementsByClassName(className) as HTMLCollectionOf<HTMLElement>)
    const GroupHeader = Array.from(document.getElementsByClassName(className + '-i') as HTMLCollectionOf<HTMLElement>)
    for (const child of children) {
      if (child.nodeName === 'LABEL')
        if (child.style.display === 'none') child.style.display = 'table'
        else child.style.display = 'none'
    }
    for (const child of GroupHeader) {
      child.className === 'fas fa-angle-double-up fa-lg ' + className + '-i'
        ? (child.className = 'fas fa-angle-double-down fa-lg ' + className + '-i')
        : (child.className = 'fas fa-angle-double-up fa-lg ' + className + '-i')
    }
  }

  // Build layer groups
  public injectLayerControlGroups() {
    if (document.getElementsByClassName('layer-control-legend').length < 1) {
      // Insert map group
      const BaseOverlay = Array.from(
        document.getElementsByClassName('leaflet-control-layers-base') as HTMLCollectionOf<HTMLElement>
      )

      // Insert assets group
      const OverlayGroup = Array.from(
        document.getElementsByClassName('leaflet-control-layers-overlays') as HTMLCollectionOf<HTMLElement>
      )

      const labels = document.querySelectorAll('label')

      this.ripplesLayers.forEach((layer) => {
        const layerClassName = layer.group.replace(' ', '-').toLowerCase()

        if (document.getElementsByClassName(layerClassName + '-div').length < 1) {
          // Create Group Div
          const Group = document.createElement('div')
          Group.className = 'layer-control-legend ' + layerClassName + '-div'
          Group.innerText = layer.group
          const Arrow = document.createElement('i')
          Arrow.className = 'fas fa-angle-double-up fa-lg ' + layerClassName + '-i'
          Arrow.id = 'group-arrow'
          Group.appendChild(Arrow)
          if (layer.group !== 'Maps' && layer.group !== 'Assets') {
            Group.style.borderTop = '1px solid rgb(175, 175, 175)'
            Group.style.padding = '6px 0px 0px 0px'
          }
          Group.onclick = (e) => {
            this.toggleGroupVisibility(layerClassName)
            e.stopImmediatePropagation()
            e.stopPropagation()
          }

          // If parent node exists
          if (
            (layer.group === 'Maps' && BaseOverlay[0] !== null) ||
            (layer.group !== 'Maps' && OverlayGroup[0] !== null)
          ) {
            // Search in all label elements
            labels.forEach((child) => {
              if (child.innerText === layer.name) {
                layer.group === 'Maps'
                  ? BaseOverlay[0].insertBefore(Group, child)
                  : OverlayGroup[0].insertBefore(Group, child)
              }
            })
          }
        }
        labels.forEach((child) => {
          // Vertical Profile:  custom description for empty data
          const span = child.querySelector('span')
          if (span && child.innerText.trim() === 'No data available') {
            const emptyProfilesLabel = child.firstElementChild
            if (emptyProfilesLabel) {
              const emptyProfilesInput = emptyProfilesLabel.firstElementChild
              if (emptyProfilesInput) {
                emptyProfilesInput.classList.add('no-profiles-data')
                span.classList.add('no-profiles-data')
              }
            }
          }

          if (child.innerText === layer.name) {
            child.className = layerClassName

            Array.from(child.children as HTMLCollectionOf<HTMLElement>).forEach((element) => {
              if (!element.id) {
                element.id = 'remove-border'
                if (this.scientistLayers.includes(child.innerText)) {
                  const cog = document.createElement('i')
                  cog.className = 'fas fa-cog ' + child.innerText
                  cog.id = 'scale-options'
                  cog.style.display = 'none'
                  element.appendChild(cog)

                  if (child.innerText.includes('NetCDF File')) {
                    const upload = document.createElement('i')
                    upload.className = 'fas fa-upload ' + child.innerText
                    upload.id = 'upload-options'
                    element.appendChild(upload)
                  }
                  // cuxv data
                  if (child.innerText.includes('Risk Management')) {
                    const redirect = document.createElement('i')
                    redirect.className = 'fas fa-external-link-alt ' + child.innerText
                    redirect.id = 'redirect-options'
                    redirect.title = 'View data'
                    redirect.addEventListener('click', () => {
                      const apiURL = process.env.REACT_APP_API_BASE_URL
                      const redirectUrl = apiURL + '/cuxvData/index.html'
                      // Open the URL in a new tab
                      window.open(redirectUrl, '_blank')
                    })
                    element.appendChild(redirect)

                    // redirect to thredds server
                    const redirectToThredds = document.createElement('i')
                    redirectToThredds.className = 'fas fa-download ' + child.innerText
                    redirectToThredds.id = 'redirect-options'
                    redirectToThredds.title = 'Thredds Server'
                    redirectToThredds.addEventListener('click', () => {
                      const threddsURL = 'https://rampsrv.lsts.pt/thredds/catalog/aos/catalog.html'
                      // Open the URL in a new tab
                      window.open(threddsURL, '_blank')
                    })
                    element.appendChild(redirectToThredds)
                  }
                  // cuxv data acoustic
                  if (child.innerText.includes('Acoustic Performance')) {
                    const redirect = document.createElement('i')
                    redirect.className = 'fas fa-external-link-alt ' + child.innerText
                    redirect.id = 'redirect-options'
                    redirect.title = 'View data'
                    redirect.addEventListener('click', () => {
                      const apiURL = process.env.REACT_APP_API_BASE_URL
                      const redirectUrl = apiURL + '/cuxvDataAcoustic/index_aca.html'
                      // Open the URL in a new tab
                      window.open(redirectUrl, '_blank')
                    })
                    element.appendChild(redirect)
                  }
                }
              }
            })
          }
        })
      })
    }
  }

  // Handle layers scale
  public injectLayerScale() {
    if (
      document.getElementsByClassName('rainbow-legend').length < 1 ||
      document.getElementsByClassName('redblue-legend').length < 1
    ) {
      const labels = document.querySelectorAll('label')
      labels.forEach((child) => {
        if (this.scientistLayers.includes(child.innerText)) {
          const layerId = this.scientistLayers.indexOf(child.innerText)
          child.onclick = (e) => {
            this.toggleLayerScale(child, this.scientistLayers[layerId])
          }
          this.ripplesLayers.forEach((element) => {
            if (element.name === child.innerText) this.buildLayerScale(child, element)
          })
        }
      })
    }
  }

  // Build layer scale
  public buildLayerScale(child: any, layer: LayerSettings) {
    if (document.getElementById(layer.name) === null) {
      // Create Map Legend Div
      const LegendDiv = document.createElement('div')
      LegendDiv.id = layer.name
      LegendDiv.style.display = 'none'
      LegendDiv.onclick = (e) => {
        e.preventDefault()
      }

      const layerOptions = document.getElementsByClassName('fas fa-cog ' + layer.name)[0] as HTMLElement
      if (layerOptions !== undefined && layerOptions !== null) {
        layerOptions.onclick = (e) => {
          e.preventDefault()
          e.stopPropagation()
          this.handleStyleChange(layer, child, LegendDiv)
        }
      }

      const layerUpload = document.getElementsByClassName('fas fa-upload ' + layer.name)[0] as HTMLElement
      if (layerUpload !== undefined && layerUpload !== null) {
        layerUpload.onclick = (e) => {
          e.preventDefault()
          e.stopPropagation()
          this.handleUploadFile(child, layer)
        }
      }

      // @ts-ignore
      const units = layer.activeLayer !== undefined ? layer.subLayers.get(layer.activeLayer).units : layer.units
      if (units !== undefined && units !== null) {
        const LegendUnits = document.createElement('div')
        LegendUnits.innerText = units
        LegendUnits.className = 'legend-units'
        LegendDiv.appendChild(LegendUnits)
      }

      // @ts-ignore
      const isLog = layer.activeLayer !== undefined ? layer.subLayers.get(layer.activeLayer).isLog : layer.isLog

      // Create Scale Table
      if (layer.lowerValue !== undefined && layer.higherValue !== undefined) {
        const LegendNumbersTable = document.createElement('table')
        LegendNumbersTable.className = 'legend-numbers'
        const LegendNumbersThread = document.createElement('thread')
        const LegendNumbersTableTr = document.createElement('tr')

        const LegendNumbersTableTrLower = document.createElement('input')
        LegendNumbersTableTrLower.type = 'number'
        LegendNumbersTableTrLower.id = layer.name + ' td-lower'
        LegendNumbersTableTrLower.onclick = (e) => {
          e.stopPropagation()
        }
        LegendNumbersTableTrLower.onchange = (e) => {
          this.handleRangeChange(e, layer, true)
        }

        const LegendNumbersTableTr1 = document.createElement('td')
        LegendNumbersTableTr1.id = layer.name + ' td-1'
        const LegendNumbersTableTr2 = document.createElement('td')
        LegendNumbersTableTr2.id = layer.name + ' td-2'
        const LegendNumbersTableTr3 = document.createElement('td')
        LegendNumbersTableTr3.id = layer.name + ' td-3'

        const LegendNumbersTableTrHigher = document.createElement('input')
        LegendNumbersTableTrHigher.type = 'number'
        LegendNumbersTableTrHigher.id = layer.name + ' td-higher'
        LegendNumbersTableTrHigher.onchange = (e) => this.handleRangeChange(e, layer, false)
        LegendNumbersTableTrHigher.onclick = (e) => {
          e.stopPropagation()
        }

        this.populateLegendNumbers(
          isLog,
          layer.lowerValue,
          layer.higherValue,
          LegendNumbersTableTrLower,
          LegendNumbersTableTr1,
          LegendNumbersTableTr2,
          LegendNumbersTableTr3,
          LegendNumbersTableTrHigher
        )

        // Build Layer Legend Div with table
        LegendDiv.appendChild(LegendNumbersTable)
        LegendNumbersTable.appendChild(LegendNumbersThread)
        LegendNumbersThread.appendChild(LegendNumbersTableTr)
        LegendNumbersTableTr.appendChild(LegendNumbersTableTrLower)
        LegendNumbersTableTr.appendChild(LegendNumbersTableTr1)
        LegendNumbersTableTr.appendChild(LegendNumbersTableTr2)
        LegendNumbersTableTr.appendChild(LegendNumbersTableTr3)
        LegendNumbersTableTr.appendChild(LegendNumbersTableTrHigher)
      }

      LegendDiv.className =
        'layer-legend ' + (layer.legendStyle === 'x-Rainbow' ? 'rainbow' : layer.legendStyle) + '-legend'

      child.appendChild(LegendDiv)
    }
  }

  // Toggle layer scale visibility (show or hide)
  public toggleLayerScale(child: HTMLLabelElement, layerName: string) {
    const checkBox = child.children[0].children[0] as HTMLInputElement
    if (checkBox !== null) {
      const legend = document.getElementById(layerName)
      const legendOptions = document.getElementsByClassName('fas fa-cog ' + layerName)
      if (legend !== null && legendOptions[0] !== null && legendOptions[0] !== undefined)
        if (checkBox.checked) {
          legend.style.display = 'flex'
          ;(legendOptions[0] as HTMLElement).style.display = 'flex'
        } else {
          legend.style.display = 'none'
          ;(legendOptions[0] as HTMLElement).style.display = 'none'
        }
    }

    const layerScaleDiv = document.getElementsByClassName('layer-scales-div ' + layerName)[0] as HTMLElement
    const layerOpacityDiv = document.getElementsByClassName('layer-opacity-div ' + layerName)[0] as HTMLElement
    const layerDropdownDiv = document.getElementsByClassName('layer-dropdown-div ' + layerName)[0] as HTMLElement

    if (layerScaleDiv !== null && layerScaleDiv !== undefined) {
      layerScaleDiv.remove()
      layerOpacityDiv.remove()
    }
    if (layerDropdownDiv !== undefined) {
      layerDropdownDiv.remove()
    }
  }

  public uploadFile(e: Event, layer: LayerSettings) {
    // @ts-ignore
    if (
      e.target !== null &&
      e.target !== undefined &&
      (e.target as HTMLInputElement).files !== null &&
      (e.target as HTMLInputElement).files !== undefined
    ) {
      const apiURL = process.env.REACT_APP_API_BASE_URL
      const formData = new FormData()
      // @ts-ignore
      formData.append('file', (e.target as HTMLInputElement).files[0])
      if (apiURL !== undefined) {
        formData.append('baseUrl', apiURL)
      }

      fetch(apiURL + '/layer/file', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          type: 'formData',
        },
        body: formData,
      }).then(
        (res) => {
          console.log(res)
          if (res.ok) {
            NotificationManager.success('Uploaded netCDF file')
            const now = new Date(Date.now())
            // @ts-igonore
            if (layer.fileName !== undefined) {
              // @ts-igonore
              layer.fileName =
                now.toISOString().split('T')[0].replace('-', '').replace('-', '') +
                now.toTimeString().split(':')[0] +
                now.toTimeString().split(':')[1] +
                '.nc'
            }
          } else if (res.status === 401) {
            NotificationManager.error('Cannot upload netCDF file')
          }
        },
        (e) => {
          NotificationManager.error('Cannot upload netCDF file')
        }
      )
    }
  }

  public handleUploadFile(child: HTMLElement, layer: LayerSettings) {
    if (document.getElementsByClassName('layer-upload-div').length <= 0) {
      const layerInfoDiv = document.createElement('div')
      layerInfoDiv.className = 'layer-upload-div'

      const uploadDiv = document.createElement('div')
      const upload = document.createElement('i')
      upload.className = 'fas fa-upload'
      upload.id = 'layer-upload'
      upload.style.cursor = 'pointer'
      const input = document.createElement('input')
      input.type = 'file'
      input.accept = '.nc'
      input.onchange = (e) => {
        e.preventDefault()
        this.uploadFile(e, layer)
      }
      input.style.cursor = 'pointer'
      upload.appendChild(input)
      uploadDiv.appendChild(upload)
      layerInfoDiv.appendChild(uploadDiv)

      const layerNameTextDiv = document.createElement('div')
      layerNameTextDiv.innerText = 'Layer Name: '
      const layerNameText = document.createElement('input')
      layerNameText.type = 'text'
      layerNameText.oninput = (e) => {
        // @ts-ignore
        layer.layerName = e.target.value
      }
      layerNameTextDiv.appendChild(layerNameText)
      layerInfoDiv.appendChild(layerNameTextDiv)

      child.appendChild(layerInfoDiv)
    } else {
      child.removeChild(document.getElementsByClassName('layer-upload-div')[0] as HTMLElement)
    }
  }

  public handleStyleChange(layer: LayerSettings, child: HTMLElement, LegendDiv: HTMLDivElement) {
    let scaleDivExists = false

    const layerScaleDiv = document.getElementsByClassName('layer-scales-div ' + layer.name)[0] as HTMLElement
    const layerOpacityDiv = document.getElementsByClassName('layer-opacity-div ' + layer.name)[0] as HTMLElement
    const layerDropdownDiv = document.getElementsByClassName('layer-dropdown-div ' + layer.name)[0] as HTMLElement
    const layerUploadDiv = document.getElementsByClassName('layer-upload-div ')[0] as HTMLElement

    if (layerScaleDiv !== null && layerScaleDiv !== undefined) {
      scaleDivExists = true
      layerScaleDiv.remove()
      layerOpacityDiv.remove()
    }
    if (layerDropdownDiv !== undefined) {
      layerDropdownDiv.remove()
    }
    if (layerUploadDiv !== undefined) {
      layerUploadDiv.remove()
    }

    if (!scaleDivExists) {
      const scalesDiv = document.createElement('div')
      scalesDiv.className = 'layer-scales-div ' + layer.name
      scalesDiv.onclick = (e) => {
        e.preventDefault()
      }

      const opacityDiv = document.createElement('div')
      opacityDiv.className = 'layer-opacity-div ' + layer.name
      opacityDiv.style.display = 'flex'
      opacityDiv.onclick = (e) => {
        e.preventDefault()
      }

      const dropdownDiv = document.createElement('div')
      dropdownDiv.className = 'layer-dropdown-div ' + layer.name
      dropdownDiv.style.display = 'flex'
      dropdownDiv.onclick = (e) => {
        e.preventDefault()
        e.stopPropagation()
      }

      const rainbowDiv = document.createElement('div')
      rainbowDiv.className = 'layer-scale rainbow-legend'
      rainbowDiv.onclick = () =>
        this.updateLayerInfo(child, scalesDiv, opacityDiv, dropdownDiv, LegendDiv, layer, 'rainbow')

      const ferretDiv = document.createElement('div')
      ferretDiv.className = 'layer-scale ferret-legend'
      ferretDiv.onclick = () =>
        this.updateLayerInfo(child, scalesDiv, opacityDiv, dropdownDiv, LegendDiv, layer, 'ferret')

      const greyscaleDiv = document.createElement('div')
      greyscaleDiv.className = 'layer-scale greyscale-legend'
      greyscaleDiv.onclick = () =>
        this.updateLayerInfo(child, scalesDiv, opacityDiv, dropdownDiv, LegendDiv, layer, 'greyscale')

      const rbView = document.createElement('div')
      rbView.className = 'layer-scale redblue-legend'
      rbView.onclick = () =>
        this.updateLayerInfo(child, scalesDiv, opacityDiv, dropdownDiv, LegendDiv, layer, 'redblue')

      const ncviewDiv = document.createElement('div')
      ncviewDiv.className = 'layer-scale ncview-legend'
      ncviewDiv.onclick = () =>
        this.updateLayerInfo(child, scalesDiv, opacityDiv, dropdownDiv, LegendDiv, layer, 'ncview')

      if (LegendDiv.className !== 'layer-legend rainbow-legend') scalesDiv.appendChild(rainbowDiv)
      if (LegendDiv.className !== 'layer-legend ferret-legend') scalesDiv.appendChild(ferretDiv)
      if (LegendDiv.className !== 'layer-legend greyscale-legend') scalesDiv.appendChild(greyscaleDiv)
      if (LegendDiv.className !== 'layer-legend redblue-legend') scalesDiv.appendChild(rbView)
      if (LegendDiv.className !== 'layer-legend ncview-legend') scalesDiv.appendChild(ncviewDiv)

      child.appendChild(scalesDiv)

      const opacitySlider = document.createElement('input')
      opacitySlider.className = 'layer-opacity-slider'
      opacitySlider.type = 'range'
      opacitySlider.min = '0.1'
      opacitySlider.max = '1'
      opacitySlider.step = '0.05'
      if (this.ripplesLayers.get(layer.name) !== undefined && layer.opacity !== undefined) {
        // @ts-ignore
        opacitySlider.value = layer.opacity
      } else opacitySlider.value = '0.1'
      opacitySlider.onchange = (e) => this.handleOpacityChange(e, layer)
      opacitySlider.onclick = (e) => {
        e.preventDefault()
        e.stopPropagation()
      }

      opacityDiv.appendChild(opacitySlider)
      child.appendChild(opacityDiv)

      // build dropdown selector
      if (this.scientistLayers.includes(layer.name) && this.ripplesLayers.get(layer.name) !== undefined) {
        if (layer.subLayers !== undefined && layer.subLayers !== null) {
          const dropdownSelect = document.createElement('select')
          dropdownSelect.className = 'layer-dropdown-select'
          // @ts-ignore
          layer.subLayers.forEach((option: LayerInfo, i: number) => {
            const optionElement = document.createElement('option')
            optionElement.value = option.name
            optionElement.innerText = option.description
            optionElement.title = option.description
            // @ts-ignore
            if (layer.activeLayer !== undefined && option === layer.activeLayer) {
              optionElement.selected = true
            }
            dropdownSelect.onchange = (e) => {
              e.preventDefault()
              e.stopPropagation()
              this.handleLayerSelectChange(e, child, layer)
            }
            dropdownSelect.appendChild(optionElement)
          })
          dropdownDiv.appendChild(dropdownSelect)
          child.appendChild(dropdownDiv)
        }
        if (layer.times !== undefined && layer.times !== null) {
          const dropdownSelect = document.createElement('select')
          dropdownSelect.className = 'layer-dropdown-select'
          // @ts-ignore
          layer.times.forEach((time: string, i: number) => {
            const timeElement = document.createElement('option')
            timeElement.value = time
            timeElement.innerText = time
            // @ts-ignore
            if (layer.time !== undefined && time === layer.time) {
              timeElement.selected = true
            }
            dropdownSelect.onchange = (e) => {
              e.preventDefault()
              e.stopPropagation()
              layer.time = (e.target as HTMLSelectElement).value
            }
            dropdownSelect.appendChild(timeElement)
          })
          dropdownDiv.appendChild(dropdownSelect)
          child.appendChild(dropdownDiv)
        }
      }
    }
  }

  public handleLayerSelectChange(e: Event, child: HTMLElement, layer: LayerSettings) {
    // @ts-ignore
    const subLayer = layer.subLayers.get(e.target.value)

    // @ts-ignore
    layer.activeLayer = e.target.value
    // @ts-ignore
    if (layer.changeLayerName === null || layer.changeLayerName === undefined || layer.changeLayerName === true) {
      // @ts-ignore
      ;(child as HTMLElement).children[0].children[1].textContent = subLayer.description
    }
    // @ts-ignore
    ;(child as HTMLElement).children[1].children[0].textContent = subLayer.units

    // @ts-ignore
    const isLog = layer.activeLayer !== undefined ? layer.subLayers.get(layer.activeLayer).isLog : layer.isLog

    // @ts-ignore
    if (subLayer.lowerValue !== undefined && subLayer.higherValue !== undefined) {
      const lower = document.getElementById(layer.name + ' td-lower') as HTMLInputElement
      const td1 = document.getElementById(layer.name + ' td-1')
      const td2 = document.getElementById(layer.name + ' td-2')
      const td3 = document.getElementById(layer.name + ' td-3')
      const higher = document.getElementById(layer.name + ' td-higher') as HTMLInputElement

      if (lower !== null && td1 !== null && td2 !== null && td3 !== null && higher !== null) {
        // @ts-ignore
        this.populateLegendNumbers(isLog, subLayer.lowerValue, subLayer.higherValue, lower, td1, td2, td3, higher)
        // @ts-ignore
        layer.higherValue = subLayer.higherValue
        // @ts-ignore
        layer.lowerValue = subLayer.lowerValue
      }
    }
  }

  public updateLayerInfo(
    parent: HTMLElement,
    scalesDiv: HTMLDivElement,
    opacityDiv: HTMLDivElement,
    dropdownDiv: HTMLDivElement,
    LegendDiv: HTMLDivElement,
    layer: LayerSettings,
    styleName: string
  ) {
    LegendDiv.className = 'layer-legend ' + styleName + '-legend'
    if (this.scientistLayers.includes(layer.name)) {
      // @ts-ignore
      layer.legendStyle = styleName
    }
    parent.removeChild(scalesDiv)
    parent.removeChild(opacityDiv)
    if (parent.contains(dropdownDiv)) parent.removeChild(dropdownDiv)
  }

  public handleRangeChange(e: Event, layer: LayerSettings, isLowerValue: boolean) {
    if (!(e.target as HTMLInputElement).value.match(/([0-9]+[.|,][0-9])|([0-9][.|,][0-9]+)|([0-9]+)/)) {
      NotificationManager.warning('Invalid input, value must be a number', 'Warning:', 8000, true)
      return
    }

    if (this.scientistLayers.includes(layer.name)) {
      // @ts-ignore
      const isLog = layer.activeLayer !== undefined ? layer.subLayers.get(layer.activeLayer).isLog : layer.isLog

      const inputValue = Number((e.target as HTMLInputElement).value)

      if (
        // @ts-ignore
        (isLowerValue && inputValue >= layer.higherValue) ||
        // @ts-ignore
        (!isLowerValue && layer.lowerValue >= inputValue)
      ) {
        // @ts-ignore
        ;(e.target as HTMLInputElement).value = isLowerValue ? layer.lowerValue : layer.higherValue

        NotificationManager.warning(
          isLowerValue ? 'Value must be lower than highest value' : 'Value must be higher than lowest value',
          'Warning:',
          8000,
          true
        )
        return
      } else if (isLog && inputValue <= 0) {
        // @ts-ignore
        ;(e.target as HTMLInputElement).value = isLowerValue ? layer.lowerValue : layer.higherValue

        NotificationManager.warning('Layer is logarithmic; value must be higher than 0', 'Warning:', 8000, true)
        return
      }

      if (isLowerValue)
        // @ts-ignore
        layer.lowerValue = Number(inputValue.toFixed(2))
      else layer.higherValue = Number(inputValue.toFixed(2))

      this.updateScaleValues(layer)
    }
  }

  public handleOpacityChange(e: Event, layer: LayerSettings) {
    if (this.scientistLayers.includes(layer.name) && this.ripplesLayers.get(layer.name) !== undefined) {
      // @ts-ignore
      this.ripplesLayers.get(layer.name).opacity = Number((e.target as HTMLInputElement).value)
    }
  }

  public updateScaleValues(layer: LayerSettings) {
    // @ts-ignore
    const isLog = layer.activeLayer !== undefined ? layer.subLayers.get(layer.activeLayer).isLog : layer.isLog

    if (layer.lowerValue !== undefined && layer.higherValue !== undefined) {
      const lower = document.getElementById(layer.name + ' td-lower') as HTMLInputElement
      const td1 = document.getElementById(layer.name + ' td-1')
      const td2 = document.getElementById(layer.name + ' td-2')
      const td3 = document.getElementById(layer.name + ' td-3')
      const higher = document.getElementById(layer.name + ' td-higher') as HTMLInputElement

      if (lower !== null && td1 !== null && td2 !== null && td3 !== null && higher !== null)
        this.populateLegendNumbers(isLog, layer.lowerValue, layer.higherValue, lower, td1, td2, td3, higher)
    }
  }

  public populateLegendNumbers(
    isLog: boolean | undefined,
    lowerValue: number,
    higherValue: number,
    lower: HTMLInputElement,
    td1: HTMLElement,
    td2: HTMLElement,
    td3: HTMLElement,
    higher: HTMLInputElement
  ) {
    lower.value = String(lowerValue.toFixed(2))
    higher.value = String(higherValue.toFixed(2))

    if (isLog !== undefined && isLog === true) {
      const minv = Math.log(lowerValue)
      const maxv = Math.log(higherValue)

      td1.textContent = String(
        Math.exp(
          minv +
            ((maxv - minv) / (higherValue - lowerValue)) * (lowerValue + (higherValue - lowerValue) / 4 - lowerValue)
        ).toFixed(3)
      )
      td2.textContent = String(
        Math.exp(
          minv +
            ((maxv - minv) / (higherValue - lowerValue)) *
              (lowerValue + ((higherValue - lowerValue) * 2) / 4 - lowerValue)
        ).toFixed(3)
      )
      td3.textContent = String(
        Math.exp(
          minv +
            ((maxv - minv) / (higherValue - lowerValue)) * (higherValue - (higherValue - lowerValue) / 4 - lowerValue)
        ).toFixed(3)
      )
    } else {
      td1.textContent = String((lowerValue + (higherValue - lowerValue) / 4).toFixed(2))
      td2.textContent = String((lowerValue + ((higherValue - lowerValue) * 2) / 4).toFixed(2))
      td3.textContent = String((lowerValue + ((higherValue - lowerValue) * 3) / 4).toFixed(2))
    }
  }
}

export default Sidebar
