import React, { Component } from 'react'
import { Marker, Polygon } from 'react-leaflet'
import { connect } from 'react-redux'
import {
  selectContact,
  selectMapElement,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
} from '../../../redux/ripples.actions'
import { BlueCircleIcon } from './Icons'
import IContact from '../../../model/IContact'
import 'react-datepicker/dist/react-datepicker.css'
import IRipplesState from '../../../model/IRipplesState'
import IMapElement from '../../../model/IMapElement'
import { LatLng } from 'leaflet'
import { IUser } from '../../../model/IAuthState'
import { ToolSelected } from '../../../model/ToolSelected'
import { IMapElementIntrusion } from '../../../model/IIntrusion'

interface PropsType {
  mapElements?: IMapElement[]
  currentUser: IUser
  toolSelected: ToolSelected
  intrusion: IMapElementIntrusion[]
  selectContact: (_: IContact | null) => void
  selectMapElement: (_: IMapElement | null) => void
  setSidePanelTitle: (title: string) => void
  setSidePanelContent: (content: any) => void
  setSidePanelVisibility: (v: boolean) => void
  sidePanelVerification: () => void
}

class MapElement extends Component<PropsType, {}> {
  constructor(props: PropsType) {
    super(props)

    this.state = {}
  }

  public buildMapElements() {
    if (this.props.mapElements) {
      return this.props.mapElements.map((mapElement) => {
        // Parse color
        let color = 'blue'
        this.props.intrusion.forEach((i) => {
          if (i.mapElementLabel === mapElement.label) {
            if (i.type === 'ALERT') {
              color = 'red'
            }
            if (i.type === 'WARNING') {
              color = 'orange'
            }
          }
        })

        const positions: L.LatLng[] = []
        mapElement.positions.forEach((pos) => {
          const mapElementPosition: L.LatLng = new LatLng(pos[0], pos[1])
          positions.push(mapElementPosition)
        })

        if (positions.length === 1) {
          return (
            <Marker
              key={'MapElement_' + mapElement.label}
              position={[positions[0].lat, positions[0].lng]}
              icon={new BlueCircleIcon()}
              title={mapElement.label}
              onClick={(e: any) => this.onMapElementClick(e, mapElement)}
            />
          )
        } else {
          return (
            <Polygon
              key={'MapElement_' + mapElement.label}
              positions={positions}
              color={color}
              title={mapElement.label}
              onClick={(e: any) => this.onMapElementClick(e, mapElement)}
            />
          )
        }
      })
    }
  }

  public onMapElementClick(evt: any, mapElement: IMapElement) {
    if (this.props.toolSelected !== ToolSelected.MEASURE) {
      evt.originalEvent.view.L.DomEvent.stopPropagation(evt)
      this.props.setSidePanelTitle(mapElement.label)
      this.props.setSidePanelContent(this.getDisplayProperties(mapElement))
      this.props.setSidePanelVisibility(true)

      this.props.selectMapElement(mapElement)

      // avoid mixed content
      this.props.selectContact(null)

      // Move sidepanel if sidebar is open
      this.props.sidePanelVerification()
    }
  }

  private getDisplayProperties(mapElement: IMapElement) {
    let properties = {}
    if (this.props.currentUser.role === 'L0') {
      properties = {
        Timestamp: mapElement.timestamp,
        Type: mapElement.type === null ? 'UNDEFINED' : mapElement.type,
        MaxAge: mapElement.maxAge.length === 0 ? 'UNDEFINED' : mapElement.maxAge,
        User: mapElement.user === null ? 'UNDEFINED' : mapElement.user,
      }
    } else {
      properties = {
        Timestamp: mapElement.timestamp,
        Type: mapElement.type === null ? 'UNDEFINED' : mapElement.type,
        MaxAge: mapElement.maxAge.length === 0 ? 'UNDEFINED' : mapElement.maxAge,
        User: mapElement.user === null ? 'UNDEFINED' : '******',
      }
    }
    return properties
  }

  public render() {
    return <>{this.buildMapElements()}</>
  }
}

function mapStateToProps(state: IRipplesState) {
  return {
    contactSelected: state.contactSelected,
    currentUser: state.auth.currentUser,
    mapElementSelected: state.mapElementSelected,
    toolSelected: state.toolSelected,
    intrusion: state.intrusion,
  }
}

const actionCreators = {
  selectContact,
  selectMapElement,
  setSidePanelContent,
  setSidePanelTitle,
  setSidePanelVisibility,
  sidePanelVerification,
}

export default connect(mapStateToProps, actionCreators)(MapElement)
